/* App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #ffffff;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ff4655;
  cursor: pointer;
}

h1:hover {
  color: #ff5968;
}


.selectors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.selectors select {
  margin: 10px;
  padding: 10px;
  font-size: 1em;
  background-color: #1f1f1f;
  color: #ffffff;
  border: 1px solid #ff4655;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.selectors select:hover {
  border-color: #ff5968;
}

.lineup-item {
  background-color: #1f1f1f;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.2s;
}

.lineup-item:hover {
  transform: translateY(-5px);
}

.lineup-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
}

.lineup-item p {
  margin-top: 10px;
  font-size: 0.9em;
  line-height: 1.4;
  color: #b0b0b0;
}

.lineups {
  gap: 20px;
}

.lineups:not(.no-lineups-container) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.lineups.no-lineups-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.no-lineups {
  font-size: 1.2em;
  color: #b0b0b0;
  text-align: center;
  margin: 0; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
  z-index: 1001;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}
/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.pagination button {
  background-color: #1f1f1f;
  color: #ffffff;
  border: 1px solid #ff4655;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s, border-color 0.2s;
}

.pagination button:hover {
  background-color: #ff4655;
  border-color: #ff5968;
}

.pagination button.active {
  background-color: #ff4655;
  border-color: #ff5968;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
